import { Dispatch, SetStateAction } from 'react'

import { IIndividualCTAContent } from '../../types/IBaseContentful'

enum CtaColor {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'tertiary' = 'tertiary',
  'outline' = 'outline',
  'ghost' = 'ghost',
}

// This is a component input type
export interface ICtaParams {
  handleClick?: Dispatch<SetStateAction<boolean>>
  label: string
  icon?: string
  color: CtaColor
  to?: string
  targetBlank?: Boolean
}

export const mapCtaContent = (
  ctacontent: IIndividualCTAContent | IIndividualCTAContent[],
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
): ICtaParams | ICtaParams[] | null => {
  if (ctacontent === null || ctacontent === undefined) {
    return null
  }

  if (Array.isArray(ctacontent)) {
    return ctacontent.map((cta) => getCtaParams(cta, changePopupState))
  }
  
  return getCtaParams(ctacontent, changePopupState)
}

// Will return an object with cta params
export const getCtaParams = (
  cta: IIndividualCTAContent,
  changePopupState: Dispatch<SetStateAction<boolean>> | null = null
): ICtaParams => {
  const resultingCTA = {
    // This to: is here for backwards compatibility but should be removed in ui lib
    to: cta?.ctaTo ?? '',
    handleClick: changePopupState,
    label: cta?.ctaLabel ?? '',
    icon: cta?.icon ?? '',
    color: CtaColor[cta?.color] || CtaColor.primary,
    targetBlank: cta?.openNewTab,
  }

  // cta cannnot have both to and handleClick at the same time
  if (resultingCTA.to === '/kontakt') {
    // to has to be a string as it will be checked in the ui lib with startsWith
    resultingCTA.to = ''
  } else {
    delete resultingCTA.handleClick
  }
  return resultingCTA
}
