import { graphql } from 'gatsby'
import React, { Dispatch } from 'react'

import { SupportHeader, SupportContent, Footer } from '@alteos/ui'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IHeaderContent, IFooterContent } from '../types/IBaseContentful'
import { IHeroTitle } from '../types/registerForm'
import { getCtaParams } from '../utils/contentMaps/ctaContent'

export interface ISupportiveData {
  contentfulSupportivePageContent: IContentfulSupportivePageContent
}
export interface IContentfulSupportivePageContent {
  headerContent: IHeaderContent
  heroTitle: IHeroTitle
  necessaryInformation: INecessaryInformation
  footerContent: IFooterContent
}

export interface INecessaryInformation {
  necessaryInformation: string
}

interface ISupportiveTemplateData {
  data: ISupportiveData
}

const mapContent = (
  data: IContentfulSupportivePageContent,
  setPopupState: Dispatch<React.SetStateAction<boolean>>
) => {
  const header = {
    items: data.headerContent.content.map((item) => getCtaParams(item)),
  }

  const hero = {
    title: data.heroTitle.heroTitle,
  }

  const body = data.necessaryInformation.necessaryInformation

  const footer = {
    copyright: data.footerContent.copyright.copyright,
    items: data.footerContent.content.map((item) => getCtaParams(item, setPopupState)),
  }

  return {
    header,
    hero,
    body,
    footer,
  }
}

const SupportivePage = ({ data }: ISupportiveTemplateData): JSX.Element => (
  <Layout>
    {({ setPopupState }) => {
      const content = mapContent(data.contentfulSupportivePageContent, setPopupState)

      return (
        <>
          <SupportHeader {...content.hero} headerContent={content.header} />
          <SupportContent content={content.body} dataTest="SupportContent" />
          <Footer {...content.footer} />
          <Seo noIndex={false} />
        </>
      )
    }}
  </Layout>
)

export const query = graphql`
  query ($slug: String!) {
    contentfulSupportivePageContent(slug: { eq: $slug }) {
      headerContent {
        content {
          ctaLabel
          ctaTo
        }
      }
      heroTitle {
        heroTitle
      }
      necessaryInformation {
        necessaryInformation
      }
      footerContent {
        copyright {
          copyright
        }
        content {
          ctaLabel
          ctaTo
        }
      }
    }
  }
`

export default SupportivePage
